<template>
    
    <transition name="intro-build"
                mode="out-in">

        <div class="oxygen-calculator-intro" v-if="ready">

            <h3>How much oxygen will you need</h3>
            <h4>to get to the summit and back?</h4>

            <o2-button @click.native="gotoOnBoarding"
                       :label="labels.begin"
                       icon="arrow"
                       ref="buttonElement"></o2-button>

        </div>

    </transition>

</template>


<script>

import { mapGetters } from 'vuex'
import O2Button from './O2Button'

export default {

    name: 'OxygenCalculatorIntro',

    components: {
        O2Button
    },

    data() {
        return {
            componentName: this.$options.name + ' Component',
            ready: false,
            labels: {
                begin: 'Begin'
            }
        }
    },

    computed: {

        ...mapGetters('OxygenCalculator', {
            content: 'landingContent'
        })

    },
    
    mounted() {

        this.$nextTick(() => {
            this.ready = true;
        })

    },
    
    methods: {
        /**
         * @function gotoOnBoarding
         * @description Button click event handler.
         * 
         */
        gotoOnBoarding() {
            this.$router.push({ name: 'OxygenCalculatorOnBoarding' })
        }

    }
    
}

</script>


<style lang="scss" scoped>

.oxygen-calculator-intro {
    position: relative;

    h3 {
        color: #0f005d;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: $font-weight-bold;
        margin: 240px 0 0 0;
        user-select: none;
        
        @media (min-width: 576px) { // RED (SM)
            font-size: 28px;
            margin: 200px 0 0 0;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    h4 {
        color: #3f75ad;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: $font-weight-semibold;
        margin: 5px 0 50px 0;
        user-select: none;

        @media (min-width: 576px) { // RED (SM)
            font-size: 25px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }
}

.highlight {
    padding: 1rem;
}

</style>
