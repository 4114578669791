<template>

    <div class="oxygen-calculator-landing">

        <oxygen-calculator-intro></oxygen-calculator-intro>

    </div>

</template>


<script>

import OxygenCalculatorIntro from '@/components/oxygen-calculator/OxygenCalculatorIntro'

export default {

    name: 'OxygenCalculatorLanding',

    components: {
        OxygenCalculatorIntro
    },

    data() {
        return {
            componentName: this.$options.name + ' View'
        }
    },

    computed: {

    },
    
    created() {
        this.$emit('show::landingContent')
    },
    
    mounted() {
        
    },
    
    methods: {

    }
    
}

</script>


<style lang="scss" scoped>

.oxygen-calculator-landing {
    position: relative;
    top: 27%;
    z-index: 25;
    transform: translateZ(1000px);
}

</style>
